import React from 'react';
import { PathService } from '../../services';
import { OurStoryViewContext, TemplateProps } from '../../types';
import { OurStoryPostView } from '../../views';

const OurStoryPost: React.FunctionComponent<
  TemplateProps<OurStoryViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurStoryPostView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
      sideNav={JSON.parse(pageContext.sideNav)}
    />
  );
};

export default OurStoryPost;
